.footer {
    padding-top: 150px;
    margin-top: -200px;
    color: #fff;
    text-align: center;
    
    @include respond-down(small) {
        padding-top: 110px;
    }

    .wrapper {
        .logo {
            margin-bottom: 50px;
            
            @include respond-down(small) {
                margin-bottom: 25px;
            }
        }
        
        .lead {
            font-size: 31px;
            margin-bottom: 35px;
            
            @include respond-down(small) {
                font-size: 20px;
                margin-bottom: 12px;
            }
            @include respond-down(mobile){
                font-size: 12px;
            }
        }
        
        .respond {
            font-size: 37px;
            margin-bottom: 35px;
            font-weight: 200;

            @include respond-down(small) {
                font-size: 24px;
                margin-bottom: 12px;
            }
        }

        a.big-phone {
            margin-bottom: 70px;
            
            @include respond-down(small) {
                margin-bottom: 45px;
            }
        }
        
        .team {
            width: 289px;
            display: inline-block;
            color: #fff;
            font-size: 21px;
            font-weight: 200;
            text-decoration: none;
            margin-bottom: 50px;
            opacity: 0.5;
            transition: opacity 0.3s ease-out;
            
            &:hover {
                opacity: 1;
            }

            @include respond-down(small) {
                width: 173px;
                font-size: 14px;
            }

            svg {
                width: 100%;
                margin-left: -20px;
            }
        }
    }
}
