@import "settings";

@import "_cds_mixins";
@import "_cds_animation";
@import "_cds_utils";
@import "_cds_grid";
@import "_cds_flex";

// @import "_vendors/modal";

@import "_parts/typo";
@import "_parts/base";
@import "_parts/menu";
@import "_parts/footer";
@import "_parts/article";
@import "_parts/counter";
@import "_parts/cart-box";
@import "_parts/modals";
@import "_parts/toolkit";
@import "_parts/cart_success";

@import "_main/index";
@import "_main/history";
@import "_main/material";
@import "_main/learn";
@import "_main/contacts";
@import "_main/article";
@import "_main/notfound";
