$grid-columns: 12;
$max-row-width: 1080;

$grid: (
  mobile: (
    from: 0,
    to: 1280,
    gutter: 12
  ),
  small: (
    from: 0,
    to: 1920,
    gutter: 12
  ),
  large: (
    from: 1920,
    gutter: 30
  )
);

$font: Muller, sans-serif;
$primary: #0074E4;
$default: #494949;
$action: #FFC000;
$action-active: #FF9200;
$dark: #0E5599;
$text: #7D7D7D;
