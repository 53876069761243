.cart-box {
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 300;
    background: #F2F2F2;
    box-shadow: -9px 0 48px rgba(0,0,0,0.18);
    width: 500px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    
    .close-button {
        @include justify-content(space-between);
        @include align-items(center);
        height: 150px;

        @include respond-down(small) {
            height: 100px;
        }
    }
    
    .get-many-wrapper {
        text-align: center;
    }
    
    .wrapper {
        max-width: 390px;
        margin: 0 100px;

        @include respond-down(small) {
            max-width: 270px;
            margin: 0 68px;
        }
    }
    
    .remove-button {
        position: absolute;
        right: -32px;
        top: 0px;
        opacity: 0.3;
        transition: opacity 0.3s ease-in-out;
        
        &:hover {
            opacity: 1;
        }
    }
    
    .scroll-box {
        position: relative;
        overflow: auto;
        padding-right: 38px;
        margin-right: -20px;
        margin-bottom: 20px;

        &::-webkit-scrollbar {
            position: absolute;
            right: -6px;
            width: 6px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #FFFFFF; 
            border-radius: 3px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $primary; 
            opacity: 0.7;
            border-radius: 3px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $primary; 
        }
    }

    hr {
        border: none;
        height: 1px;
        background: #DDDDDD;
        margin: 0;
    }
    
    .final-box {
        padding: 20px 0;

        .helper {
            width: 50%;
            text-transform: uppercase;
            font-size: 14px;
            color: $default;
            text-align: center;
            padding: 6px 0;
        }
        .value {
            width: 50%;
            text-align: right;
            font-size: 21px;
            color: #17B978;
        }
        .form-control {
            width: 100%;
            margin-bottom: 15px;
        }
        .sum {
            margin-bottom: 15px;
        }

        @include respond-down(small) {
            padding: 10px 0;
        }
    }

    .cart.close {
        &:after {
            background-image: url('/static/frontend/svg/cart-close.svg');
        }
    }

    .svirel {
        background: #fff;
        border-radius: 3px;
        position: relative;
        text-align: center;
        height: 140px;
        margin-bottom: 35px;


        @include respond-down(small){
            height: 95px;
            margin-bottom: 25px;
        }
        
        .title {
            left: 115px;
            line-height: 1.1;
            right: 0;
            position: absolute;

            @include respond-down(small){
                left: 71px;
            }
            @include respond-up(large){
                font-size: 20px;
            }
        }
        
        .price {
            width: 115px;
            top: 85px;
            left: 0;
            position: absolute;
            text-align: right;

            @include respond-down(small){
                width: 71px;
                top: 55px;
            }
            @include respond-up(large){
                font-size: 20px;
            }
        }
        
        .counter {
            position: absolute;
            right: 0;
            left: 115px;
            top: 45px;

            @include respond-down(small){
                left: 70px;
                top: 40px;
            }
        }

        .image {
            width: 125px;
            height: 80px;
            background: #eee no-repeat center center;
            background-size: cover;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 3px 0 0 0;
            
            @include respond-down(small){
                width: 71px;
                height: 45px;
            }
            .title {
                
            }
            .price {
                
            }
        }
        
        
    }
}

.cart-box-background {
    position: fixed;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    display: none;
    z-index: 290;
}

html.overflow {
    overflow: hidden;
    
    .cart-box {
        opacity: 1;
    }
}