.article-page {
    .intro {
        text-align: center;
        color: #fff;
        height: 650px;
        padding-top: 250px;

        @include respond-down(small) {
            height: 515px;
            padding-top: 200px;
        }
        
        & > .background {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: #17B978;
            
            .top {
                position: absolute;
                z-index: 15;
                right: 0;
                top: 0;
                width: 80%;
            }
            
            .image {
                position: absolute;
                z-index: 10;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 10;
                background: no-repeat center center;
                background-size: cover;
                opacity: 0.3;
            }
        }
        
        .wrapper {
            //@include align-items(center);
            //@include justify-content(center);
            //height: 300px;

            h1 {
                margin-bottom: 20px;
                //max-width: 1200px;
            }
        }
    }

    .article {
        color: $default;
        padding-bottom: 100px;
        padding-top: 40px;
        
        h3 {
            padding-top: 180px;
            text-align: center;
            font-size: 60px;
            font-weight: bold;
            margin-bottom: 120px;

            @include respond-down(small) {
                padding-top: 80px;
                font-size: 40px;
                margin-bottom: 65px;
            }
        }

        h4 {
            font-size: 32px;

            @include respond-down(small) {
                font-size: 24px;
            }
        }
        
        .page {
            width: 866px;
            font-size: 27px;
            font-weight: 200;
            margin: 0 auto;
            padding-bottom: 200px;
            position: relative;

            @include respond-down(small) {
                width: 513px;
                line-height: 1.6;
                font-size: 16px;
            }
            
            img {
                max-width: 100%;
            }
            
            .decor {
                display: none;
                width: 150px;
                position: absolute;
                top: 20px;
                
                &.bird-x {
                    left: -200px;
                }
                &.bird-y {
                    right: -200px;
                }
            }
        }
    }

    &.document {

        .intro {
            background: #1793B9;
            height: 150px;
            margin-top: -250px;

            @include respond-down(small) {
                height: 100px;
                margin-top: -200px;
            }
        }

        .article {

            h3 {
                padding-top: 40px;
                margin-top: 0;

                @include respond-down(small) {
                    //padding-top: 40px;
                }
            }
            .page {
                width: 1080px;

                p {
                    margin-bottom: 15px;
                }
            }
        }
    }
}


