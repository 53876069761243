.cart-success-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;

  visibility: hidden;
  opacity: 0;
  @include transform(translateY(100%));
  @include transition(all 0.4s);

  &.show, &:hover {
    visibility: visible;
    opacity: 1;
    @include transform(translateY(0));
  }

  .cart-success-block {
    padding: 15px 0;
    background-color: rgba(0,0,0,0.83);
  }

  .close-block {
    a {
      display: block;
      width: 32px;
      height: 33px;
      background-position: 50% 50%;
      background-size: cover;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABCCAMAAADQfyDnAAAANlBMVEUAAAD////////////////////////////////////////////////////////////////////xY8b8AAAAEXRSTlMA+4dI+JNRbCAR7uPMxoxDDs6j8oUAAACsSURBVFjD7dY7EoQgFAXRB+iMzkdl/5u1jDpvqjR5BGanDQi4MX7W92Dg36u28/XzV9GFuU8LBeVbBAXnKUhPwXoK1lOwnoL1FKynYD0F6ylYT8F6CtZTsJ6C9RSsp2A9BespWE/BegrWU9CegvcU8K6AlwW8LeBl4YeXhaI899d7HfJTLRSMb9ym87kfcj/kfsj9kPsh98Pd+2Eb2Q/fNWL/+Pe7Ldf3iEfPCYbFDU1SKtLVAAAAAElFTkSuQmCC");
    }
  }

  .inner {
    display: flex;
    align-items: center;
    height: 70px;

    .action-block {
      flex: 1 1 auto;
      display: flex;
      align-items: center;

      .caption {
        flex: 1 1 auto;
        padding-right: 30px;
        font-size: 22px;
        color: #fff;
        font-size: 23px;
        float: left;
        line-height: 48px;
      }

      .order {
        flex: 0 0 215px;
        width: 215px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        text-decoration: none;
        text-transform: uppercase;
      }
    }

    .close-block {
      flex: 0 0 67px;
      padding-left: 35px;
      text-align: right;
    }
  }

.inner {
      height: 56px;

      .action-block {
        display: block;

        .caption {
          display: block;
          font-size: 15px;
        }
        
        .button {
            background: #fff;
            &:hover {
                background: $primary;
            }
        }

        .order {
          text-align: left;
          display: block;
          padding-top: 10px;
          color: #fff;
          font-size: 14px;
          width: auto;
          height: auto;
          line-height: 1.3;
        }
      }
    }
  
}