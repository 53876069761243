
.toolkit {
	margin-bottom: 100px;

	.img_wrapper {
		margin-right: 30px;

		.img {
		}
	}

	.text_wrapper {
		text-align: left;

		.title {
			margin-top: 50px;
			margin-bottom: 30px;
			font-family: Muller;
			font-size: 28px;
			font-weight: 700;
			color: #494949;
		}

		.text {
			margin-bottom: 40px;
			line-height: 21px;
			font-size: 16px;
			font-weight: 300;
			letter-spacing: 0.8px;
			color: #7d7d7d;
		}

		.counter {
			@include justify-content(flex-start);
			margin-bottom: 15px;
			padding: 0px;

			.remove {

				.this {
				}
			}

			.number {
			}

			.add {

				.this {
				}
			}
		}
		.button {
			width: 180px;

			&.noselect {
				border-color: #b8b8b8;
				background-color: #b8b8b8;
				box-shadow: unset;
				color: #fff;
			}
		}
	}
}
