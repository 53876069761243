h1 {
    font-family: LemonTuesday;
    font-size: 105px;

    @include respond-down(small){
        font-size: 70px;
    }
}

h2 {
    font-family: LemonTuesday;
}

a {
    cursor: pointer;
    color: $primary;
    transition: all 0.25s ease-out;
    text-decoration: none;
    
    &:visited {
        color: $dark;
    }

    &:hover {
        color: $action;
    }
}
