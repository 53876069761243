html, body {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    background: #fff;
    vertical-align: baseline;
}

* {
    box-sizing: border-box;
    outline: none;
}

body {
    font-size: $body-font-size;
    font-family: $font;
    line-height: 1.3;
    font-size: 24px;

    @include respond-down(small) {
        font-size: 16px;
    }
}

.content {
    position: relative;
    z-index: 20;
}

.container {
    margin: auto;
    width: 1780px;
    z-index: 5;
    position: relative;
    
    @include respond-down(small){
        width: 1060px;
    }
    
    @include respond-down(mobile){
        width: auto;
        overflow: hidden;
        padding: 0 20px;
    }
    
}

.flex {
    @include flexbox;
    &.wrap {
        @include flex-wrap(wrap);
    }
}

.logo {
    display: inline-block;
    width: 260px;
    height: 70px;
    background: url(/static/frontend/svg/logo.svg) no-repeat center center; 
    background-size: contain;

    @include respond-down(small){
        @include flex(0 0 155px);
        width: 155px;
        height: 44px;
    }
}

.big-phone {
    display: block;
    font-size: 57px;
    font-weight: bold;
    color: #fff;
    
    @include respond-down(small) {
        font-size: 35px;
    }
}

.middle {
    padding: 20px 0;
    text-align: center;
}

.button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    height: 80px;
    line-height: 80px;
    border-radius: 40px;
    color: $primary;
    box-shadow: inset 0 0 0 2px $primary;
    padding: 0 30px;
    transition: 
        background 0.3s ease-out, 
        color 0.3s ease-out, 
        box-shadow 0.3s ease-out;
    
    &.block {
        display: block;
        text-align: center;
    }

    &.blue {
        background: $primary;
        color: #fff;
    }
    
    &.orange {
        background: $action;
        box-shadow: none;
        color: #fff;
    }

    &.transparent {
        background: none;
        box-shadow: none;
        color: #fff;
    }

    @include respond-down(small) {
        height: 48px;
        line-height: 48px;
        border-radius: 24px;
        font-size: 16px;
    }
    
    @include respond-down(mobile) {
        
    }
    
    &:hover {
        color: #fff;
        background: $primary;
        &.orange {
            background: $action-active;
        }
        &.transparent {
            background: rgba(255,255,255,0.4);
        }
        &.blue {
            background: #0E5599;
            border-color: #0E5599;
        }
    }
}

.lead {
    font-size: 35px;

    @include respond-down(small){
        font-size: 24px;
    }
}

.shadow-items {
    margin: 0 -18px;
    .item {
        @include flex(0 0 25%);
        padding: 0 18px 50px 18px;
    }
}

.shadow-box {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 17px 22px rgba(0,0,0,0.08);
    transition: all 0.35s ease-in-out;
    
    &:hover {
        box-shadow: 0 20px 65px rgba(0,0,0,0.35);
    }
    .image {
        background-size: cover;
    }
}

.svg-background {
    overflow: hidden;
    position: relative;
    z-index: 30;
    padding-top: 150px;
    margin-top: -150px;
    
    @include respond-down(small){
        padding-top: 100px;
        margin-top: -100px;
    }

    & > .background {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        
    }

    & > svg {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 50%;
        width: 2800px;
        margin-left: -1400px;
        
        @include respond-down(small){
            width: 2200px;
            margin-left: -1100px;
        }
    }
    
    & > .wrapper {
        position: relative;
        z-index: 30;

        h1 {
            margin-bottom: 15px;
        }
    }
}

.selector {
    padding: 0;
    list-style: none;

    width: 1335px;
    border-bottom: 2px solid #E9E9F0;
    margin: 20px auto;

    @include respond-down(small){
        width: 985px;
    }

    .list-item {
        @include flex(1,1, 33.3333%);
        font-size: 20px;
        position: relative;
        
        @include respond-down(small){
            font-size: 15px;
        }
        
        &:after {
            content: '';
            height: 4px;
            border-radius: 2px;
            background: $action;
            bottom: -3px;
            left: -2px;
            right: -2px;
            position: absolute;
            opacity: 0;
        }
        
        a {
            color: #fff;
            display: inline-block;
            padding: 10px 0;
            text-decoration: none;

            &:hover {
                color: $action;
            }
        }
        
        &.current {
            &:after {
                opacity: 1;
            }

            a {
                color: $action;
            }
        }
    }
}

.pagination {
    @include flexbox;
    @include justify-content(center);

    ul, li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .item {
        border: 1px solid #D7DAE2;
        background: #fff;
        margin-left: -1px;
        color: #4D4F5C;

        &.left, &.first.active {
            border-radius: 3px 0 0 3px;
        }

        &.right, &.last.active {
            border-radius: 0 3px 3px 0;
        }
        
        &.active {
            background: $action;
            border-color: $action;
            color: #fff;

            &:hover {
                a {
                    color: #fff;
                }
            }
        }

        a {
            display: inline-block;
            text-decoration: none;
            width: 48px;
            height: 48px;
            font-size: 16px;
            line-height: 48px;

            @include respond-down(small){
                width: 32px;
                height: 32px;
                font-size: 13px;
                line-height: 32px;
            }
        }
    }    
    
}

.form-control {
    height: 80px;
    line-height: 80px;
    border-radius: 40px;
    padding: 0 30px;
    font-size: 16px;

    color: #494949;
    width: 100%;
    font-weight: 200;
    display: block;
    background: transparent;
    border: 2px solid #DADADA;

    @include respond-down(small) {
        height: 48px;
        line-height: 48px;
        border-radius: 24px;
        padding: 0 20px;
        font-size: 14px;
    }
    
    &.error {
        animation: a-ta-ta 1.5s ease-out both;
    }
}

@keyframes a-ta-ta {
    0%  { border-color: #DADADA; }
    10% { border-color: #FF7979; }
    20% { border-color: #DADADA; }
    30% { border-color: #FF7979; }
    40% { border-color: #DADADA; }
    50% { border-color: #FF7979; }
    80% { border-color: #DADADA; }
}


.privacy {
    margin: 0 -30px;
    padding: 10px 0;
    font-size: 12px;
    font-weight: 200;
    text-align: center;
    
    b {
        color: $primary;
    }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}